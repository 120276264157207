import React, { useCallback } from "react";
import { SignupStageHandlerComponent } from "./SignupStageHandlerComponent.type";
import usePromise from "../../utils/hooks/usePromise";
import { User, UserLearnerProfile } from "@unicef/shared-resources/models";
import _ from "lodash";
import { FORM_STAGE } from "../../contexts/form";
import { services } from "../../services";
import { openP2EPortal } from "../../utils/redirect";
import BaseLayout from "../../layouts/base";
import { useNavigate } from "react-router-dom";
import { PAGE_PATHS } from "../page-paths";

export const ProcessForm: SignupStageHandlerComponent = ({
  formState,
  formStage,
  setFieldValue,
  clearForm,
}) => {
  const navigate = useNavigate();

  usePromise(
    {
      fn: async () => {
        if (formStage === FORM_STAGE.READY_FOR_SUBMISSION) {
          try {
            const payload = _.pick(formState, [
              "aadhaarUIDTokenSignature",
              "aadhaarUIDToken",
              "name",
              "email",
              "mobileNumber",
              "gender",
            ]);

            if (!payload.mobileNumber) delete payload.mobileNumber;
            if (!payload.email) delete payload.email;

            const result = await services.requestSvc.post<
              User & UserLearnerProfile
            >("/create-learner", {
              ...payload,
            });

            console.log("Onboarding response", result);

            setFieldValue("isSuccessful", true);
            setFieldValue("isFormSubmitted", true);
          } catch (err: any) {
            console.log("Error occurred on form submission", err);
            setFieldValue("isSuccessful", false);
            setFieldValue("isFormSubmitted", true);
            setFieldValue("formSubmissionError", err.message);
          }
        }
      },
      runOnMount: { args: [] },
      runOnRerender: { args: [], enabled: true },
    },
    [formStage]
  );

  const retryFormSubmission = useCallback(
    () => setFieldValue("isFormSubmitted", false),
    [setFieldValue]
  );

  const goBack = useCallback(
    () => setFieldValue("isPersonalDetailsFinalised", false),
    [setFieldValue]
  );

  const redirectToP2EPortal = useCallback(async () => {
    clearForm();
    openP2EPortal();
    navigate(PAGE_PATHS.DEFAULT_NAVIGABLE);
  }, []);

  return (
    <BaseLayout noIcon>
      {formStage === FORM_STAGE.READY_FOR_SUBMISSION ? (
        <span className="text-2xl">Processing...</span>
      ) : formStage === FORM_STAGE.FAILED ? (
        <div className="flex flex-col items-center justify-center gap-y-4">
          <img
            src={require("../../assets/error.gif")}
            className="h-32 m-auto"
            alt="success-gif"
          />
          <span className="text-2xl">Verification unsuccessful</span>
          {formState.formSubmissionError ? (
            <>
              {formState.formSubmissionError}{" "}
              <button onClick={goBack} className="btn-primary w-full">
                Go back
              </button>
            </>
          ) : (
            <>
              Due to an unknown error, your account couldn't be registered.{" "}
              <button
                onClick={retryFormSubmission}
                className="btn-primary w-full"
              >
                Retry
              </button>
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-y-4">
          <img
            src={require("../../assets/success.gif")}
            className="h-32 m-auto"
            alt="success-gif"
          />
          <span className="text-2xl">Successfully verified</span>
          <span className="text-2xl font-semibold">[{formState.name}]</span>
          <p className="text-center max-w-[300px]">
            You have been successfully registered and now will be redirected to
            the P2E portal
          </p>
          <button onClick={redirectToP2EPortal} className="btn-primary w-full">
            Continue Registration
          </button>
        </div>
      )}
    </BaseLayout>
  );
};
