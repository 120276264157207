import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { PAGE_PATHS } from "./page-paths";
import { serverName, resourcesUrl } from "../config";
import { useAuth } from "../utils/hooks/useAuth";
import VLEStatsFragment, { UserOnboardingStats } from "./Home/VLEStatsFragment";
import usePromise from "../utils/hooks/usePromise";
import { services } from "../services";
import { VLE } from "@unicef/shared-resources/models";
import Loader from "../assets/animated-images/loader";
import BaseLayout from "../layouts/base";

export default function HomePage() {
  const { vle } = useAuth();

  const logout = useCallback(() => {
    document.cookie = "access_token=;domain=" + serverName;
    window.location.reload();
  }, []);

  const { result: userStats, loading: loadingUserStats } = usePromise(
    {
      fn: async () => {
        const vleCscId = vle?.cscId;
        if (vleCscId) {
          const response = await services.requestSvc.get<{
            vle: VLE;
            userOnboardingStats: UserOnboardingStats;
          }>("/get-stats-by-vle?vleCscId=" + vleCscId);

          console.log(response);

          return {
            vle: response.vle,
            totalOnboardedUsers: response.userOnboardingStats.reduce(
              (total, cur) => total + cur.onboardingCount,
              0
            ),
            totalUsersWithCourseCompletions:
              response.userOnboardingStats.reduce(
                (total, cur) => total + cur.courseCompletionCount,
                0
              ),
            monthWiseReport: response.userOnboardingStats,
          };
        }
      },
      runOnMount: { args: [] },
      runOnRerender: { enabled: true, args: [] },
    },
    [vle]
  );

  return (
    <BaseLayout>
      <div className="flex flex-col mt-8 gap-y-4">
        <div>
          <div className="text-2xl font-semibold mb-3">Welcome!</div>
          <div className="text-blue font-semibold">{vle?.email}</div>
        </div>
        <span className="italic">
          <span className="font-bold">Notice</span>: All P2E courses are free of
          cost
        </span>
        <a href={resourcesUrl} target="_blank">
          <button className="btn-secondary w-full">View Resources</button>
        </a>
        <Link to={PAGE_PATHS.SIGNUP}>
          <button className="btn-primary w-full">Onboard a User</button>
        </Link>
        {loadingUserStats ? (
          <Loader />
        ) : (
          userStats && (
            <VLEStatsFragment
              vle={userStats.vle}
              totalOnboardedUsers={userStats.totalOnboardedUsers}
              totalUsersWithCourseCompletions={
                userStats.totalUsersWithCourseCompletions
              }
              monthWiseReport={userStats.monthWiseReport}
            />
          )
        )}
        <button onClick={logout} className="btn-secondary w-full">
          Logout
        </button>
      </div>
    </BaseLayout>
  );
}
