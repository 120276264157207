import { mobileNumberRegex } from "@unicef/shared-resources/consts";
import { SignupStageHandlerComponent } from "./SignupStageHandlerComponent.type";
import { Formik } from "formik";
import * as yup from "yup";
import { renderFieldError } from "../../utils/form";
import { useCallback } from "react";
import { RegistrationFormContextShape } from "../../contexts/form";
import FormLayout from "../../layouts/form";
import { services } from "../../services";
import { Gender } from "@unicef/shared-resources/models";

type PersonalDetailsFormrResponseShape = Pick<
  RegistrationFormContextShape["formState"],
  "name" | "email" | "mobileNumber" | "gender"
>;

export const PersonalDetailsPage: SignupStageHandlerComponent = ({
  stepNumber,
  stepTitle,
  formState,
  setFieldValue,
  clearForm,
}) => {
  const handleSubmit = useCallback(
    async (values: PersonalDetailsFormrResponseShape) => {
      setFieldValue("name", values.name as string);
      setFieldValue("email", values.email as string);
      setFieldValue("mobileNumber", values.mobileNumber as string);
      setFieldValue("gender", values.gender as Gender);
      setFieldValue("isPersonalDetailsFinalised", true);

      try {
        await services.requestSvc.post(
          "/check-learner-duplicacy-by-email-mobile-number",
          {
            email: values.email || undefined,
            mobileNumber: values.mobileNumber || undefined,
          }
        );
        setFieldValue("personalDetailsErr", undefined);
        setFieldValue("isPersonalDetailsVerified", true);
      } catch (err: any) {
        setFieldValue("personalDetailsErr", err.message);
        setFieldValue("isPersonalDetailsVerified", false);
      }
    },
    [setFieldValue]
  );

  return (
    <FormLayout
      stepNumber={stepNumber}
      stepTitle={stepTitle || ""}
      clearForm={clearForm}
    >
      <Formik<PersonalDetailsFormrResponseShape>
        initialValues={{
          name: formState.name,
          mobileNumber: formState.mobileNumber,
          email: formState.email,
          gender: formState.gender,
        }}
        onSubmit={handleSubmit}
        validationSchema={yup.object({
          name: yup.string().required("Name is required"),
          email: yup.string().email("Please enter a valid email"),
          mobileNumber: yup
            .string()
            .required("Mobile Number is required")
            .matches(
              mobileNumberRegex,
              "Mobile number should match the pattern: +[COUNTRY CODE] [10-DIGIT MOBILE NUMBER]"
            ),
          gender: yup
            .string()
            .oneOf(["male", "female", "other"])
            .required("Gender is required"),
        })}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form
              className="flex flex-col gap-y-2 mt-3"
              onSubmit={handleSubmit}
            >
              {formState.personalDetailsErr ? (
                <span className="text-red-500">
                  {formState.personalDetailsErr}
                </span>
              ) : (
                ""
              )}
              <div className="flex flex-col w-full">
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue={values.name}
                  placeholder="Enter name"
                  className="input-primary"
                />
                {renderFieldError(errors, "name")}
              </div>
              <div className="flex flex-col w-full mt-4">
                <input
                  type="text"
                  name="mobileNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue={values.mobileNumber}
                  placeholder="Enter mobile number"
                  className="input-primary"
                />
                {renderFieldError(errors, "mobileNumber")}
              </div>
              <div className="flex flex-col w-full mt-4">
                <select
                  className="input-primary"
                  name="gender"
                  onChange={handleChange}
                >
                  <option>Choose gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {renderFieldError(errors, "gender")}
              </div>
              <div className="flex flex-col w-full mt-4">
                <input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue={values.email}
                  placeholder="Enter email"
                  className="input-primary"
                />
                {renderFieldError(errors, "email")}
              </div>
              <input
                type="submit"
                value={isSubmitting ? "Please wait..." : "Continue"}
                disabled={isSubmitting}
                className="btn-primary mt-4"
              />
            </form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};
