import { VLE } from "@unicef/shared-resources/models";
import moment from "moment";
import React from "react";

export type UserOnboardingStats = {
  onboardingCount: number;
  courseCompletionCount: number;
  computedYearMonth: string;
}[];

export default function VLEStatsFragment(props: {
  vle: VLE;
  totalOnboardedUsers: number;
  totalUsersWithCourseCompletions: number;
  monthWiseReport: UserOnboardingStats;
}) {
  const {
    vle,
    totalOnboardedUsers,
    totalUsersWithCourseCompletions,
    monthWiseReport,
  } = props;

  return (
    <div className="rounded-lg bg-gray-100 p-6 flex flex-col gap-y-6 justify-between">
      <div className="flex justify-between gap-x-4">
        <div className="flex flex-col gap-y-4 ml-auto">
          <h1 className="font-bold leading-7">
            Total number of users onboarded
          </h1>
          <div className="stat-number-card">{totalOnboardedUsers}</div>
        </div>

        <div className="flex flex-col gap-y-4 mr-auto">
          <h1 className="font-bold leading-7">
            Total number of users who completed course
          </h1>
          <div className="stat-number-card">
            {totalUsersWithCourseCompletions}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-y-4">
        <h1 className="font-bold leading-7">
          Monthly User Onboarding Breakdown
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {monthWiseReport.map(
            ({ computedYearMonth, onboardingCount, courseCompletionCount }) => {
              return (
                <div
                  key={computedYearMonth}
                  className="stat-number-card-month flex flex-col gap-y-3"
                >
                  <div className="title">
                    {getComputedYearMonthLabel(computedYearMonth)}
                  </div>
                  <div className="key-values">
                    <span className="key">Users Onboarded:</span>
                    <span className="value">{onboardingCount}</span>
                    <span className="key">Users Completed Course:</span>
                    <span className="value">{courseCompletionCount}</span>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}

const getComputedYearMonthLabel = (computedYearMonth: string): string => {
  const year = computedYearMonth.split("_")[0];
  const monthNumber = computedYearMonth.split("_")[1];
  const monthName = moment()
    .month(Number(monthNumber) - 1)
    .format("MMM");

  return `${monthName} ${year}`;
};
